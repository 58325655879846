



























import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'rs-input',
})
export default class RSInput extends Vue {
  @Prop({required: false, default: 'text'}) type?: string;
  @Prop({required: true}) value: any;
  @Prop({required: false}) defaultValue: any;
  @Prop({required: false}) error?: boolean;
  @Prop({required: false}) addstyle?: string;
  @Prop({required: false}) prefix?: string;
  @Prop({required: false}) actionText?: string;

  valueProxy = '';

  get listeners() {
    delete this.$listeners.input;
    return this.$listeners;
  }

  updateValue() {
    this.valueProxy = (this.$refs.input as HTMLInputElement).value;
    this.$emit('input', this.valueProxy);
  }

  mounted(){
    if(!this.value){
      this.$emit('input', this.defaultValue);
    }
  }
}
