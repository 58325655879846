



























































import Component from 'vue-class-component';
import Vue from 'vue';
import {email, required} from 'vuelidate/lib/validators'
import RsInput from '@/shared/components/fields/input.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import authCallback from '@/modules/onboarding/constants/authCallback';
import {WebAuth} from 'auth0-js';
import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {OnboardingRouter} from '@/modules/onboarding/router';

interface FormLogin {
  email: string;
  password: string;
}

@Component({
  name: 'rs-onboarding-form-login',
  components: {RsButton, RsInput},
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
      }
    }
  }
})
export default class OnboardingFormLogin extends Vue {
  OnboardingRouter = OnboardingRouter;

  webAuth: WebAuth | null = null;

  form: FormLogin = {
    email: '',
    password: ''
  }

  sending = false;
  error = '';

  submit() {
    this.error = '';
    this.sending = true;
    AUTH_SERVICE.login(this.form.email, this.form.password)
      .catch((err) => {
        this.error = err.description;
      })
      .finally(() => {
        this.sending = false;
      });
  }

  public async mounted() {
    const client_id = process.env.VUE_APP_AUTH_TALENT_CLIENT_ID as string;
    const domain = process.env.VUE_APP_AUTH_TALENT_CLIENT_DOMAIN as string;
    this.webAuth = new WebAuth({
      domain,
      clientID: client_id,
      redirectUri: authCallback,
      responseType: 'token id_token',
    });
  }
}
