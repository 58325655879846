





























import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {AUTH_SERVICE} from '@/modules/onboarding/services/auth.service';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-onboarding-social-login',
  components: {RsIcon},
})
export default class DashboardHeader extends Vue {
  Icons = Icons;

  socialLogin(type: string) {
    AUTH_SERVICE.authorize(type)
  }
}

